<template>
  <LoadingState v-if="isLoading" />
  <div v-else id="myProvision">
    <a-modal
      :maskClosable="false"
      :visible="isDownloadBeritaAcaraPdf"
      title="Add Notes"
      okText="Generate Now"
      @cancel="isDownloadBeritaAcaraPdf = false"
      :cancelButtonProps="{
        props: {
          type: 'danger',
          icon: 'close',
        },
      }"
      :okButtonProps="{
        props: {
          icon: 'download',
        },
      }"
      @ok="exportBeritaAcara"
    >
      <!-- <a-form-item label='Class'> -->
      <a-textarea
        allow-clear
        v-model="inputBeritaAcara"
        placeholder="Masukkan Laporan Berita Acara"
        :autoSize="{ minRows: 8, maxRows: 15 }"
      />
      <!-- </a-form-item> -->
      <a-row>
        <a-col class="mt-2" :span="showValidationBeritaAcara ? 20 : 0">
          <small class="text-danger" v-if="showValidationBeritaAcara"
            >Sorry. Notes must be below than 600 characters.</small
          >
        </a-col>
        <a-col :span="showValidationBeritaAcara ? 4 : 24">
          <div class="ml-auto mt-2 mb-2 text-right">
            {{ this.inputBeritaAcara.length }} / 600
          </div>
        </a-col>
      </a-row>
    </a-modal>
    <div class="coverDetail">
      <h2>
        {{ detailCbt.tipe }} {{ detailCbt.kelas }} | {{ detailCbt.mapel }}
      </h2>
      <div>{{ detailCbt.waktu_ujian }} | {{ detailCbt.tipe_cbt }}</div>
    </div>
    <div class="contentDetail">
      <div class="detail-slot">
        <a-row>
          <a-col :span="24" class>
            <div class="d-lg-flex">
              <a-form-item class="d-block d-lg-none">
                <a-select
                  @change="handleSelectViewMobile"
                  :value="selectView"
                  size="large"
                  style="width: 100%; height: 40px"
                >
                  <a-select-option value="CBT Progress">{{
                    textRadio1
                  }}</a-select-option>
                  <a-select-option value="Detail CBT Progress">{{
                    textRadio2
                  }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="d-none d-lg-block">
                <a-radio-group
                  :value="selectView"
                  button-style="solid"
                  size="large"
                  style="width: 100%; height: 48px"
                  @change="handleSelectView"
                >
                  <a-radio-button value="CBT Progress">{{
                    textRadio1
                  }}</a-radio-button>
                  <a-radio-button value="Detail CBT Progress">{{
                    textRadio2
                  }}</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item>
                <a-button
                  type="default"
                  size="large"
                  class="ml-lg-2"
                  icon="file-pdf"
                  style="width: 100%"
                  :disabled="!dataTable.length || disableExport"
                  @click.prevent="isDownloadBeritaAcaraPdf = true"
                  >Berita Acara</a-button
                >
              </a-form-item>
              <!-- <a-form-item>
                <a-button
                  type="primary"
                  size="large"
                  class="ml-lg-3"
                  icon="edit"
                  style="width:100%;"
                  :disabled="!dataTable.length || disableExport"
                  @click.prevent="isDownloadBeritaAcaraPdf = true"
                >Edit Work Status</a-button>
              </a-form-item> -->
              <a-form-item class="ml-auto">
                <a-button
                  @click.prevent="refreshTable"
                  size="large"
                  type="default"
                  style="height: 40px"
                  block
                >
                  <a-icon type="sync" :spin="loadingRefresh" />Refresh
                </a-button>
              </a-form-item>
            </div>
            <a-table
              class="table-cbt"
              v-if="selectView === 'CBT Progress'"
              :columns="columnsProgressPercentage"
              :data-source="dataTable"
              :loading="loadingTable"
              @change="handleTable"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              bordered
            >
              <span slot="ExamWorkStatusTitle">
                <span>Exam Work Status</span>
                <div class="d-flex justify-content-center mt-1">
                  <a-button
                    @click.prevent="changeStatusProgress('cancel')"
                    v-if="editStatusProgress"
                    shape="round"
                    class="ml-1"
                    icon="close"
                    size="small"
                  >
                  </a-button>
                  <a-button
                    @click.prevent="
                      changeStatusProgress(editStatusProgress ? 'save' : 'edit')
                    "
                    :type="editStatusProgress ? 'primary' : 'default'"
                    :icon="editStatusProgress ? 'save' : 'edit'"
                    shape="round"
                    class="ml-2"
                    size="small"
                  >
                  </a-button>
                </div>
              </span>
              <span slot="Percentage" slot-scope="text">
                <a-progress :percent="text" status="active" />
              </span>
              <span slot="StatusAttendance" slot-scope="text">
                <p :class="text === null ? 'text-danger' : 'text-success'">
                  {{ text === null ? "ABSENT" : "PRESENT" }}
                </p>
              </span>
              <span slot="ExamWorkStatus" slot-scope="text, record">
                <p
                  v-if="!editStatusProgress"
                  :class="
                    text === null
                      ? 'text-danger'
                      : text === 'done'
                      ? 'text-success'
                      : 'text-warning'
                  "
                >
                  {{
                    text === null
                      ? "-"
                      : text === "done"
                      ? "SUBMITTED"
                      : "ON PROGRESS"
                  }}
                </p>
                <a-select
                  v-else-if="editStatusProgress && text !== null"
                  @change="(e) => handleChangeExamWorkStatus(e, record)"
                  :value="text"
                  size="large"
                  style="width: 180px"
                >
                  <a-select-option value="on progress"
                    >ON PROGRESS</a-select-option
                  >
                  <a-select-option value="done">SUBMITTED</a-select-option>
                </a-select>
                <!-- {{record}} -->
              </span>
            </a-table>
            <a-table
              v-if="selectView === 'Detail CBT Progress'"
              :columns="columnsDetailProgress"
              :data-source="dataTableDetailProgress"
              :loading="loadingTable"
              :scroll="{ x: 50, y: 1000 }"
              size="small"
              bordered
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              class="detailProvisionProgress"
            >
              <a-icon slot="filterIcon" type="search" />
              <!-- <span slot="Name" slot-scope="text, record">{{text}} {{record.Filled}}</span> -->
              <span slot="Soal" slot-scope="text">
                <a-tag v-if="text.status === '-'">
                  {{ text.noSoalMurid }}
                  <a-icon type="minus" />
                </a-tag>
                <a-tag color="green" v-else>
                  {{ text.noSoalMurid }}
                  <a-icon type="check" />
                </a-tag>
                <!-- <a-tooltip>
                  <template
                    slot="title"
                  >{{text.charAt(2) === 'C' ? 'CORRECT': text.charAt(2) === 'W' ? 'WRONG' : 'Have not taken the exam yet'}}</template>
                  <a-tag
                    :color="text.charAt(2) === 'C' ? 'green': text.charAt(2) === 'W' ? 'volcano' : ''"
                  >{{text.charAt(0) === 'H' ? '-' : text.charAt(0) }}</a-tag>
                </a-tooltip>-->
              </span>
              <!-- <template slot="footer" slot-scope="data">{{data}}</template> -->
            </a-table>
          </a-col>
        </a-row>
      </div>
    </div>
    <div>
      <div v-if="showPrint" :class="isPrint ? ['invisible', 'position-absolute'] : 'd-none' ">
        <div id="beritaAcaraDoc" class="w-100">
          <BeritaAcaraDoc
            :isPrint="isPrint"
            :dataPrint="dataPrint"
            :format="{ width: '750px' }"
            @printDone="printDone"
          />
          <!-- height 1037px -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import LoadingState from '@/components/app/LoadingState'
// import JsPDF from 'jspdf'
// import generateBeritaAcara from '@/helpers/beritaAcara'
import randomQuestion from '@/helpers/randomQuestion'
const BeritaAcaraDoc = () => import('@/components/app/BeritaAcaraDoc')

const columnsProgressPercentage = [
  {
    title: 'No',
    dataIndex: 'No',
    // width: '3%',
  },
  {
    title: 'NIS',
    dataIndex: 'nis',
    // width: '8%',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    // width: '15%',
  },
  {
    title: 'Attendance Status',
    dataIndex: 'StatusAttendance',
    // width: '10%',
    align: 'center',
    scopedSlots: { customRender: 'StatusAttendance' },
  },
  {
    // title: 'Exam Work Status',
    dataIndex: 'ExamWorkStatus',
    // width: '10%',
    align: 'center',
    slots: { title: 'ExamWorkStatusTitle' },
    scopedSlots: { customRender: 'ExamWorkStatus' },
  },
  {
    title: 'Percentage',
    dataIndex: 'Percentage',
    // width: '30%',
    // fixed: 'right',
    align: 'left',
    scopedSlots: { customRender: 'Percentage' },
  },
]

const columnsDetailProgress = [
  // {
  //   title: 'NIS',
  //   dataIndex: 'nis',
  //   width: 98,
  //   fixed: 'left',
  // },
  {
    title: 'Name',
    dataIndex: 'Name',
    width: 300,
    fixed: 'left',
    scopedSlots: { customRender: 'Name' },
  },
  // {
  //   title: 'Class',
  //   dataIndex: 'Class',
  //   width: 100,
  //   fixed: 'left',
  // },
  {
    title: 'Answer Status By Question',
    dataIndex: 'AnswerStatus',
    width: 420,
    // fixed: 'left',
    scopedSlots: { customRender: 'MultipleChoiceAnswer' },
    children: '',
  },
]

export default {
  data() {
    return {
      isLoading: true,
      loadingTable: false,
      visibleDrawer: false,
      loadingRefresh: false,
      statusSlot: false,
      columnsProgressPercentage,
      columnsDetailProgress,
      selectView: 'CBT Progress',
      textRadio1: 'CBT Progress',
      textRadio2: 'Detail CBT Progress',
      gradeEssay: 0,
      dataDrawer: {},
      detailCbt: {},
      listPengawas: {},
      classes: [],
      selectedClass: '',
      pagination: {},
      dataTable: [],
      dataTableDetailProgress: [],
      totalMurid: '',
      kelasCBT: '',
      pgState: {
        color: '',
        answer: '',
      },
      editStatusProgress: false,
      disableExport: false,
      isDownloadBeritaAcaraPdf: false,
      inputBeritaAcara: '',
      showValidationBeritaAcara: false,
      attendancesList: [],
      tahunAjaran: '',
      idPengawasCbt: this.$route.params.id,
      isPrint: false,
      showPrint: false,
      dataPrint: {},
    }
  },
  components: {
    LoadingState, BeritaAcaraDoc,
  },
  methods: {
    printDone() {
      this.showPrint = false
      this.dataPrint = {}
      this.isPrint = false
    },
    changeStatusProgress(status) {
      if (status === 'save' || status === 'cancel') {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure want to {status} editing ? your changes will be {status === 'save' ? 'updated' : 'lost'}</div>
          ),
          onOk: () => {
            this.editStatusProgress = !this.editStatusProgress
            if (status === 'save') {
              console.log('saved')
              this.$store.dispatch('cbt/UPDATE_EXAM_WORK_STATUS', { dataAttendance: this.dataTable })
                .then(isSuccess => {
                  this.fetchTable()
                  if (isSuccess) {
                    this.$notification.success({
                      message: 'Success',
                      description: 'Exam Work Progress has been updated',
                    })
                  } else {
                    this.$notification.error({
                      message: 'Error',
                      description: 'Exam Work Progress has not been updated',
                    })
                  }
                })
            } else {
              this.fetchTable()
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      } else {
        this.editStatusProgress = !this.editStatusProgress
      }
    },
    handleChangeExamWorkStatus(value, data) {
      console.log(value, data)
      const target = this.dataTable.filter(el => el.key === data.key)[0]
      if (target) {
        target.ExamWorkStatus = value
      }
      console.log(this.dataTable)
    },
    handleSelectView(e) {
      const delay = t => new Promise(resolve => setTimeout(resolve, t))
      if (e.target.value === 'Detail CBT Progress') {
        this.disableExport = true
        this.textRadio2 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio2 = 'Detail CBT Progress'
          this.selectView = e.target.value
          this.disableExport = false
        })
      } else {
        this.textRadio1 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio1 = 'CBT Progress'
          this.selectView = e.target.value
        })
      }
    },
    handleSelectViewMobile(e) {
      this.selectView = e
    },
    handleTable(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetchDataCbtTable({
        page: pagination.current,
        id_kelas: this.selectedClass,
      })
    },
    handleGradeIndex(idSoal) {
      const answers = [...this.dataDrawer.EssayAnswer]
      const target = answers.filter(item => idSoal === item.id_soal)[0]
      return target.grade
    },
    handleSlider(value, idSoal) {
      // console.log(value, idSoal)
      const answers = [...this.dataDrawer.EssayAnswer]
      const target = answers.filter(item => idSoal === item.id_soal)[0]
      target.grade = value
      // console.log(answers)
      // this.gradeEssay = event
    },
    showDrawer(id) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => id === item.key)[0]
      this.dataDrawer = target
      this.visibleDrawer = true
      // console.log(this.dataDrawer)
    },
    onCloseDrawer() {
      this.visibleDrawer = false
    },
    numberQuestion(index) {
      return ++index
    },
    answer(idSoal, jawabanMurid) {
      const target = jawabanMurid.filter(item => idSoal === item.id_soal)[0]
      if (target) {
        return target.jawaban
      } else {
        return ''
      }
    },
    async exportBeritaAcara() {
      const tipe = this.detailCbt.tipe
      const tahun = this.tahunAjaran.tahun
      const mapel = this.detailCbt.mapel
      const ujianStart = this.detailCbt.ujianOpen
      const ujianEnd = this.detailCbt.ujianClose
      const namaPengawas = this.user.nama
      const nikPengawas = this.user.nik
      // for (let x = 0; x < this.listPengawas.length; x++) {
      //   namaPengawas = this.listPengawas[x].nama
      //   nikPengawas = this.listPengawas[x].nik
      // }
      // const data = await this.$store.dispatch('cbt/FETCH_CBT_ATTENDANCES_BY_ID', {
      //   idCbt: this.$route.params.id,
      //   page: 'all',
      // })
      let totalPeserta = 0
      let countPresent = 0
      let countAbsent = 0
      for (let i = 0; i < this.attendancesList.length; i++) {
        const attendance = this.attendancesList[i]
        totalPeserta += 1
        if (attendance.status_attendance === null) {
          countAbsent += 1
        } else {
          countPresent += 1
        }
      }
      let detailTipe = ''
      if (tipe === 'PH') {
        detailTipe = 'PENILAIAN HARIAN'
      } else if (tipe === 'PTS') {
        detailTipe = 'PENILAIAN TENGAH SEMESTER'
      } else if (tipe === 'PAS') {
        detailTipe = 'PENILAIAN AKHIR SEMESTER'
      } else if (tipe === 'PAT') {
        detailTipe = 'PENILAIAN AKHIR TAHUN'
      }
      const hari = moment(ujianStart).format('dddd')
      let detailHari = ''
      if (hari === 'Monday') {
        detailHari = 'Senin'
      } else if (hari === 'Tuesday') {
        detailHari = 'Selasa'
      } else if (hari === 'Wednesday') {
        detailHari = 'Rabu'
      } else if (hari === 'Thursday') {
        detailHari = 'Kamis'
      } else if (hari === 'Friday') {
        detailHari = 'Jumat'
      } else if (hari === 'Saturday') {
        detailHari = 'Sabtu'
      } else if (hari === 'Sunday') {
        detailHari = 'Minggu'
      } else {
        detailHari = 'Cek format hari!'
      }

      const { idPengawasCbt } = this

      await this.$store.dispatch('cbt/UPDATE_NOTES_BERITA_ACARA', {
        idPengawasCbt,
        notesBeritaAcara: this.inputBeritaAcara,
      })
      this.dataPrint = {
        ...this.dataPrint,
        kelas: this.detailCbt.kelas,
        inputBeritaAcaraRoot: this.inputBeritaAcara,
        tipe,
        tahun,
        mapel,
        ujianStart,
        ujianEnd,
        namaPengawas,
        nikPengawas,
        countPresent,
        countAbsent,
        totalPeserta,
        detailTipe,
        detailHari,
        durasi: this.detailCbt.durasi,
      }
      this.showPrint = true
      setTimeout(() => {
        this.isPrint = true
      }, 2000)
      this.$notification.success({
        message: 'Success.',
        description: 'Success downloading berita acara',
      })
      this.isDownloadBeritaAcaraPdf = false
    },
    refreshTable() {
      this.loadingRefresh = true
      this.fetchTable()
    },
    fetchHeader() {
      const { idPengawasCbt } = this
      this.isLoading = true
      this.$store.dispatch('cbt/FETCH_CBT_SCHEDULE_BY_ID', { idPengawasCbt })
        .then(res => {
          this.isLoading = false
          this.detailCbt = { ...res.cbt }
          this.inputBeritaAcara = this.detailCbt.notes_berita_acara || ''
          // console.log(this.detailCbt)
          this.detailCbt.waktu_ujian = moment(this.detailCbt.ujianOpen).format('DD MMM YYYY HH:mm') + ' - ' + moment(this.detailCbt.ujianClose).format('DD MMM YYYY HH:mm')
        })
    },
    fetchTable() {
      const { idPengawasCbt } = this
      this.loadingTable = true
      this.$store.dispatch('cbt/FETCH_TABLE_SUPERVISION', { idPengawasCbt })
        .then(dataTable => {
          this.tahunAjaran = dataTable.tahun_ajaran
          this.loadingTable = false
          let noDetail = 1
          const soalsList = dataTable.cbt_soals
          const soal = soalsList.map(row => {
            // console.log(row)
            return {
              key: row.id,
              title: (<div>{noDetail++}</div>),
              align: 'center',
              dataIndex: 'Soal' + row.id,
              scopedSlots: { customRender: 'Soal' },
              width: soalsList.length <= 5 ? 100 : soalsList.length <= 15 ? 62 : 100,
            }
          })
          const attendancesList = dataTable.cbt_attendances
          this.attendancesList = attendancesList
          const dataTableDetailProgress = attendancesList.map(rowMurid => {
            const jawabanMultipleChoiceMurid = {}
            let soalAcak = []
            let soalAcakMultipleChoice = []
            soalsList.forEach(soal => {
              if (soal.tipe_soal === 'Multiple Choice') {
                soalAcakMultipleChoice.push(soal)
              }
            })
            // console.log('masuk', soalAcakMultipleChoice)
            soalAcakMultipleChoice = soalAcakMultipleChoice.length ? randomQuestion(soalAcakMultipleChoice, rowMurid.kelas_tahun_ajaran.murid.nis) : []
            let soalAcakEssay = []
            soalsList.forEach(soal => {
              if (soal.tipe_soal === 'Essay') {
                soalAcakEssay.push(soal)
              }
            })
            soalAcakEssay = soalAcakEssay.length ? randomQuestion(soalAcakEssay, rowMurid.kelas_tahun_ajaran.murid.nis) : []
            soalAcak = [
              ...soalAcakMultipleChoice,
              ...soalAcakEssay,
            ]
            // soalAcakMultipleChoice = randomQuestion(soalAcakMultipleChoice, rowMurid.murid.nis)
            // console.log(soalAcak)
            soalAcak.forEach((acak, j) => {
              if (acak) {
                soal.forEach(el => {
                  if (acak.id === el.key) {
                    const jawaban = JSON.parse(rowMurid.jawaban)
                    if (jawaban) {
                      for (let i = 0; i < jawaban.length; i++) {
                        const rowJawaban = jawaban[i]
                        if (el.key === rowJawaban.id_soal) {
                          // console.log('Murid ' + rowMurid.id_murid + 'Jawaban no id' + el.id + ' ' + rowJawaban.jawaban + ' KJ ' + el.kunci_jawaban)
                          jawabanMultipleChoiceMurid[`Soal${el.key}`] = {
                            noSoalMurid: j + 1,
                            status: 'filled',
                          }
                          break
                        }

                        if (i === jawaban.length - 1 && el.key !== rowJawaban.id_soal) {
                          jawabanMultipleChoiceMurid[`Soal${el.key}`] = {
                            noSoalMurid: j + 1,
                            status: '-',
                          }
                        }
                      }
                    } else {
                      // console.log('masuk', el.id)
                      jawabanMultipleChoiceMurid[`Soal${el.key}`] = {
                        noSoalMurid: j + 1,
                        status: '-',
                      }
                    }
                  }
                })
              }
            })
            return {
              key: rowMurid.id_kelas_tahun_ajaran,
              nis: rowMurid.kelas_tahun_ajaran.murid.nis,
              Name: rowMurid.kelas_tahun_ajaran.murid.nama,
              ...jawabanMultipleChoiceMurid,
            }
          })
          this.columnsDetailProgress[1].children = soal
          this.dataTableDetailProgress = dataTableDetailProgress
          this.loadingRefresh = false
          const dataMurid = dataTable.cbt_attendances
          // console.log(dataTable)
          const totalSoal = dataTable.hitungCBTsoal
          let no = 1
          if (dataMurid.length) {
            this.dataTable = dataMurid.map(el => {
              const jawaban = el.jawaban ? JSON.parse(el.jawaban).length : 0
              const percentage = parseInt((jawaban / totalSoal) * 100)
              return {
                key: el.id,
                No: no++,
                nis: el.kelas_tahun_ajaran.murid.nis,
                Name: el.kelas_tahun_ajaran.murid.nama,
                Percentage: percentage,
                StatusAttendance: el.status_attendance,
                ExamWorkStatus: el.status_attendance,
              }
            })
            // console.log(this.dataTable)
          }
        })
      // .then(res => {
      //   this.totalMurid = res.totalMurid
      //   const kelas = res.kelas ? `${res.kelas.tingkat}-${res.kelas.simbol}` : '-'
      //   this.kelasCBT = kelas
      //   this.loadingTable = false
      //   this.loadingRefresh = false
      //   this.classes = res.kelasTingkat
      //   let no = 1
      //   const soalEssay = res.cbt.cbt_soals.filter(row => {
      //     return row.tipe_soal === 'Essay'
      //   })
      //   const soalEssayBaru = soalEssay.map(row => {
      //     return {
      //       id: row.id,
      //       tipe_soal: row.tipe_soal,
      //       nomor_soal: row.nomor_soal,
      //       soal: row.soal,
      //       jawaban: row.jawaban,
      //       kunci_jawaban: row.kunci_jawaban,
      //       bobot_soal: row.bobot_soal,
      //     }
      //   })

      //   const soalMultipleChoice = res.cbt.cbt_soals.filter(row => {
      //     return row.tipe_soal === 'Multiple Choice'
      //   })

      //   const soalColumn = soalMultipleChoice.map(row => {
      //     return {
      //       key: row.id,
      //       title: (<div>{no++}</div>),
      //       align: 'center',
      //       dataIndex: 'Soal' + row.id,
      //       scopedSlots: { customRender: 'Soal' },
      //       width: soalMultipleChoice.length > 5 ? 45 : 100,
      //     }
      //   })
      //   const dataTableAnalytics = res.cbt.cbt_attendances.map(rowMurid => {
      //     const jawabanMultipleChoiceMurid = {}
      //     let correct = 0
      //     let wrong = 0
      //     let filled = 0
      //     let totalfilled = 0
      //     soalMultipleChoice.forEach(el => {
      //       const jawaban = JSON.parse(rowMurid.jawaban)
      //       if (jawaban) {
      //         for (let i = 0; i < jawaban.length; i++) {
      //           const rowJawaban = jawaban[i]
      //           if (el.id === rowJawaban.id_soal) {
      //             filled++
      //             // console.log('Murid ' + rowMurid.id_murid + 'Jawaban no id' + el.id + ' ' + rowJawaban.jawaban + ' KJ ' + el.kunci_jawaban)
      //             if (el.kunci_jawaban === rowJawaban.jawaban) {
      //               correct++
      //               jawabanMultipleChoiceMurid[`Soal${el.id}`] = `${rowJawaban.jawaban}|CORRECT`
      //             } else {
      //               wrong++
      //               jawabanMultipleChoiceMurid[`Soal${el.id}`] = `${rowJawaban.jawaban ? rowJawaban.jawaban : '-'}|WRONG`
      //             }
      //             break
      //           }

      //           if (i === jawaban.length - 1 && el.id !== rowJawaban.id_soal) {
      //             wrong++
      //             jawabanMultipleChoiceMurid[`Soal${el.id}`] = '-'
      //           }
      //         }
      //       } else {
      //         jawabanMultipleChoiceMurid[`Soal${el.id}`] = el.id % 2 === 0 ? '-' : 'true'
      //       }
      //     })
      //     totalfilled = (filled / soalMultipleChoice.length) * 100
      //     return {
      //       key: rowMurid.id,
      //       nis: rowMurid.murid.nis,
      //       Name: rowMurid.murid.nama,
      //       Class: rowMurid.murid.kela.tingkat + '-' + rowMurid.murid.kela.simbol,
      //       ...jawabanMultipleChoiceMurid,
      //       Correct: correct,
      //       Wrong: wrong,
      //       Filled: totalfilled,
      //     }
      //   })
      //   this.columnsAnalytics[1].children = soalColumn
      //   this.dataTableAnalytics = dataTableAnalytics
      //   this.dataTable = res.cbt.cbt_attendances.map(row => {
      //     const essayAnswerBundle = []
      //     soalEssayBaru.forEach(el => {
      //       const jawaban = JSON.parse(row.jawaban)
      //       if (jawaban) {
      //         const essayAnswer = jawaban.filter(rowJawaban => {
      //           return rowJawaban.tipe_soal === 'Essay'
      //         })
      //         if (essayAnswer.length) {
      //           for (let i = 0; i < essayAnswer.length; i++) {
      //             const rowJawaban = essayAnswer[i]
      //             if (el.id === rowJawaban.id_soal) {
      //               essayAnswerBundle.push({
      //                 status: 'filled',
      //                 tipe_soal: 'Essay',
      //                 id_soal: essayAnswer[i].id_soal,
      //                 jawaban: essayAnswer[i].jawaban,
      //                 grade: 0,
      //               })
      //               break
      //             }

      //             if (i === essayAnswer.length - 1 && el.id !== rowJawaban.id_soal) {
      //               essayAnswerBundle.push({
      //                 status: 'unfilled',
      //                 tipe_soal: 'Essay',
      //                 id_soal: el.id,
      //                 jawaban: '',
      //                 grade: 0,
      //               })
      //             }
      //           }
      //         } else {
      //           essayAnswerBundle.push({
      //             status: 'unfilled',
      //             tipe_soal: 'Essay',
      //             id_soal: el.id,
      //             jawaban: '',
      //             grade: 0,
      //           })
      //         }
      //       }
      //     })
      //     // const jawaban = row.jawaban ? JSON.parse(row.jawaban) : ''
      //     // let essayAnswer = ''
      //     // if (jawaban) {
      //     //   essayAnswer = jawaban.filter(rowJawaban => {
      //     //     return rowJawaban.tipe_soal === 'Essay'
      //     //   })
      //     //   essayAnswer = essayAnswer.map(rowMap => {
      //     //     return {
      //     //       id_soal: rowMap.id_soal,
      //     //       grade: 0,
      //     //     }
      //     //   })
      //     // }
      //     return {
      //       key: row.id,
      //       nis: row.murid.nis,
      //       Name: row.murid.nama,
      //       Class: row.murid.kela.tingkat + '-' + row.murid.kela.simbol,
      //       Answered: row.id % 2 === 0 ? 20 : 15,
      //       NotAnswered: row.id % 2 === 0 ? 15 : 20,
      //       TotalGrade: row.total_nilai,
      //       Attendance: row.total_nilai != null ? 'present' : 'absent',
      //       EssayGrade: row.nilai_essay,
      //       MultipleChoiceGrade: row.nilai_pg,
      //       Soal: soalEssayBaru,
      //       MultipleChoiceAnswer: row.jawaban ? JSON.parse(row.jawaban) : '',
      //       EssayAnswer: essayAnswerBundle,
      //       // EssayAnswer: essayAnswer,
      //       // ...jawabanMultipleChoiceMurid,
      //     }
      //   })
      //   // console.log(this.dataTable)
      //   const pagination = { ...this.pagination }
      //   pagination.total = res.totalMurid
      //   pagination.pageSize = 25
      //   this.pagination = pagination
      // })
    },
    cekAnswer(soal, jawabanMurid) {
      // console.log(jawabanMurid)
      // jawabanMurid.forEach(el => {
      // console.log(row.nomor_soal === soal.nomor_soal)
      // if (soal.nomor_soal === el.nomor_soal) {
      // console.log('Soal ke'+soal.nomor_soal+' = ' + el.jawaban)
      //     if (soal.kunci_jawaban === row.jawaban) {
      //       this.pgState.answer = row.jawaban
      //       this.pgState.color = '#41B883'
      //     } else {
      //       this.pgState.answer = row.jawaban
      //       this.pgState.color = '#F91E22'
      //     }
      //   }
      // })
      // console.log(this.pgState)
      return this.pgState
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
  },
  created() {
    this.fetchHeader()
    this.fetchTable()
  },
  watch: {
    inputBeritaAcara(newVal, oldVal) {
      if (newVal.length > 600) {
        this.inputBeritaAcara = newVal.split('').slice(0, 600).join('')
        // console.log(oke)
        this.showValidationBeritaAcara = true
        setTimeout(() => {
          this.showValidationBeritaAcara = false
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss">
#myProvision {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 20px 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }

  .contentDetail .detail-slot {
    padding: 10px 0;
  }

  .table-cbt {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
    }
  }

  .detailProvisionProgress {
    .ant-table-fixed-left {
      @media (max-width: 426px) {
        width: 50%;
        overflow-x: auto !important;
      }
    }
    .ant-table-scroll {
      .ant-table-body .ant-table-fixed colgroup col:first-child {
        @media (max-width: 426px) {
          width: 12rem !important;
          min-width: 12rem !important;
          overflow-x: hidden;
        }
      }
      .ant-table-header .ant-table-fixed colgroup col:first-child {
        @media (max-width: 426px) {
          width: 12rem !important;
          min-width: 12rem !important;
        }
      }
    }
  }
}
</style>
